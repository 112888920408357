var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.terms.content.replace(/\n/g, '<br>'))
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }